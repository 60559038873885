import Vuex from 'vuex'
import Vue from 'vue'
import clubs from './modules/clubs'
import config from './modules/config'
import meetings from './modules/meetings'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production'

export const store = new Vuex.Store({
    modules: {
        config,
        clubs,
        meetings,
    },
    strict: debug,
})
