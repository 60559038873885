import Vue from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import {router} from "@/router";
import {store} from "@/store";

import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
// Vue.use(ToastService);
import moment from 'moment';
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

Vue.use(ConfirmationService);
Vue.use(ToastService);
Vue.use(PrimeVue, {
    locale: {
        monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октбярь", "Ноябрь", "Декабрь"],
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чтв", "Пт", "Сб"]
    }
})

Vue.config.productionTip = false

moment.locale('ru')
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
