<template>
    <div class="p-d-flex p-flex-column">
        <router-link
            v-for="club in $store.state.clubs.list_admin"
            :key="club['id']"
            class="card"
            :to="'/clubs/' + club['id']"
        >
            <h3>{{ club['name'] }}</h3>
        </router-link>
    </div>
</template>

<script>
export default {
    name: "HomePage"
}
</script>

<style scoped>

</style>