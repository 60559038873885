const state = () => ({
    list: undefined,
    current: undefined
})

const actions = {
    async syncAPIList({commit, rootState}, club_id) {
        let url = '/v1/clubs/' + String(club_id) + '?all_meetings=1'
        let response = await rootState.config.api_client.get(url)
        console.log(response)
        if (response.status === 200) {
            let result_payload = response.data['result']['meetings']
            commit('updateList', result_payload)
        }
    },
    async syncAPIData({commit, rootState}, meeting_id) {
        let url = '/v1/clubs/meetings/' + String(meeting_id)
        let response = await rootState.config.api_client.get(url)
        if (response.status === 200) {
            let result_payload = response.data['result']
            commit('updateCurrent', result_payload)
        }
    },
}

const mutations = {
    clearList(state) {
        state.list = undefined
    },
    updateList(state, data) {
        state.list = data
    },
    clearCurrent(state) {
        state.current = undefined
    },
    updateCurrent(state, data) {
        state.current = data
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}