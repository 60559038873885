<template>
    <div id="app" v-if="loaded">
        <router-view></router-view>
        <ConfirmDialog></ConfirmDialog>
    </div>
</template>

<script>
import ConfirmDialog from 'primevue/confirmdialog';

export default {
    name: 'App',
    components: {
        ConfirmDialog,
    },
    data: function () {
        return {
            loaded: false
        };
    },
    mounted: function () {
        const self = this
        const token = this.$route.query.token
        this.$store.commit('config/updateAccessToken', token)
        this.$store.dispatch('config/createAxiosClient').finally(() => {
            this.$store.dispatch('clubs/syncAPIList').finally(() => {
                self.loaded = true;
            })
        })
    }
}
</script>

<style>
#app {
    font-family: Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 100px;
    padding: 0 16px;
}

.card {
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 0 24px 4px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    color: black;
    text-decoration: none;
}

.card h3 {
    margin: 0;
}

input.p-inputtext, textarea.p-inputtext {
    border: 1px solid rgba(67, 66, 68, 0.5);
    border-radius: 6px;
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover {
    border-color: #2D2FB6 !important;
    box-shadow: 0 0 2px 2px rgba(45, 47, 182, 0.2) !important;
}

div.p-toast-center {
    left: 50%;
    top: 100px;
    min-width: 20vw;
    transform: translate(-50%, 0);
}

a, a:hover, a:focus, a:visited {
    border: none;
    outline: none;
    text-decoration: none;
    color: inherit;
    -webkit-tap-highlight-color: transparent;
}
.p-dialog .p-dialog-content {
    padding: 2rem 0.5rem 2rem 0.5rem !important;
}
</style>
