const state = () => ({
    list_admin: undefined,
})

const actions = {
    async syncAPIList({commit, rootState}) {
        let url = '/v1/clubs/my_clubs'
        let response = await rootState.config.api_client.get(url)
        if (response.status === 200) {
            let result_payload = []
            response.data['result'].forEach(function (elem) {
                if (elem['is_owner']) {
                    result_payload.push(elem)
                }
            })
            commit('updateListAdmin', result_payload)
        }
    },
}

const mutations = {
    clearListAdmin(state) {
        state.list_admin = undefined
    },
    updateListAdmin(state, data) {
        state.list_admin = data
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}