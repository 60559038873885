<template>
    <div class="p-p-2 meetingForm" style="padding-top: 0;" v-if="loaded || new_meeting">
        <h2 style="margin-top: 0;" v-if="new_meeting">Новое мероприятие</h2>
        <h2 style="margin-top: 0;" v-else>Изменить мероприятие</h2>
        <div class="p-fluid p-text-left">
            <div class="p-field">
                <label for="name">Название</label>
                <it
                    type="text"
                    id="name"
                    placeholder="Встреча клуба"
                    v-model="formData.name"
                />
            </div>
            <div class="p-field">
                <label for="address">Адрес</label>
                <it
                    type="text"
                    id="address"
                    placeholder="Главный корпус"
                    v-model="formData.address"
                />
            </div>
            <div class="p-field">
                <label for="place">Аудитория</label>
                <it
                    type="text"
                    id="place"
                    placeholder="Коворкинг"
                    v-model="formData.place"
                />
            </div>
            <div class="p-field">
                <label for="description">Описание</label>
                <ta
                    id="description"
                    v-model="formData.description"
                    :autoResize="true"
                    rows="5"
                    cols="30"
                />
            </div>
            <div class="p-field">
                <label for="date">Дата</label>
                <cl
                    id="date"
                    v-model="date"
                    :minDate="tomorrow"
                    :touchUI="true"
                    dateFormat="dd.mm.yy"
                    autocomplete="off"
                />
            </div>
            <div class="p-field">
                <label for="time_start">Время начала</label>
                <cl
                    id="time_start"
                    v-model="time_start"
                    :touchUI="true"
                    :showTime="true"
                    :timeOnly="true"
                />
            </div>
            <span v-if="new_meeting">После нажатия кнопки «Создать», всем членам клуба будет отправлено пуш-уведомление о новой встрече. <br>Перед созданием проверьте правильность введённых данных.</span>
            <div class="p-field" v-if="new_meeting">
                <p>
                    <cb v-model="checked" :binary="true"/>&nbsp;&nbsp;Я все проверил
                </p>
            </div>
            <div class="p-d-flex">
                <btn
                    label="Удалить"
                    v-if="!new_meeting"
                    class="delete-button p-mr-2"
                    @click="deleteMeeting()"
                />
                <btn
                    :label="new_meeting ? 'Создать' : 'Сохранить'"
                    class="save-button p-mr-2"
                    :disabled="
                        new_meeting && (
                        this.formData.name.length === 0 ||
                        this.formData.address.length === 0||
                        this.date.length === 0 ||
                        this.time_start.length === 0 ||
                        this.formData.place.length === 0 ||
                        !this.checked)"
                    @click="saveForm()"
                />
            </div>
        </div>
        <Toast position="center"/>
    </div>
</template>

<script>
import Calendar from 'primevue/calendar';
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Toast from 'primevue/toast';
import moment from 'moment';
import Checkbox from 'primevue/checkbox';
export default {
    name: "AddMeeting",
    components: {
        btn: Button,
        cl: Calendar,
        it: InputText,
        ta: Textarea,
        cb: Checkbox,
        Toast
    },
    data() {
        return {
            checked: false,
            tomorrow: null,
            new_meeting: this.$route.name === 'new-meeting',
            loaded: false,
            formData: {
                dt_start: '',
                description: null,
                name: '',
                address: '',
                place: '',
            },
            date: '',
            time_start: '',
        }
    },
    // created: function () {
    //     this.tomorrow = new Date();
    //     this.tomorrow.setDate(new Date().getDate() + 1);
    //     this.date = this.tomorrow
    // },
    methods: {
        deleteMeeting: async function () {
            const self = this;
            this.$confirm.require({
                message: 'Вы уверены, что хотите удалить встречу?',
                header: 'Подтверждение',
                acceptLabel: 'Да',
                rejectLabel: 'Нет',
                icon: 'pi pi-exclamation-triangle',
                accept: async function ()  {
                    const response = await self.$store.state.config.api_client.delete('/v1/clubs/meetings/' + self.$route.params.meeting_id)
                    if (response.status === 200) {
                        self.$toast.add({severity: 'success', summary: 'Успешно удалено', life: 1000});
                        setTimeout(() => {
                            self.$router.go(-1)
                        }, 1000)
                    }
                },
                reject: () => {

                }
            });
        },
        saveForm: async function () {
            const text_date = moment(this.date).format('YYYY-MM-DD')
            this.formData['dt_start'] = text_date + 'T' + moment(this.time_start).format('HH:mm')
            let response = undefined
            if (this.new_meeting) {
                response = await this.$store.state.config.api_client.post('/v1/clubs/' + this.$route.params.id + '/meetings', this.formData)
            } else {
                response = await this.$store.state.config.api_client.patch('/v1/clubs/meetings/' + this.$route.params.meeting_id, this.formData)
            }
            if (response.status === 201 || response.status === 200) {
                let message = 'Успешно создано'
                if (!this.new_meeting) {
                    message = 'Успешно сохранено'
                }
                this.$toast.add({severity: 'success', summary: message, life: 1000});
                const self = this;
                setTimeout(() => {
                    self.$router.go(-1)
                }, 1000)
            } else {
                this.$toast.add({severity: 'error', summary: 'Что-то пошло не так', group: 'toastplace', life: 1000});
            }
        }
    },
    mounted: function () {
        if (!this.new_meeting) {
            const self = this;
            this.$store.dispatch('meetings/syncAPIData', this.$route.params.meeting_id).finally(() => {
                self.loaded = true
                self.formData.description = self.$store.state.meetings.current.description
                self.formData.name = self.$store.state.meetings.current.name
                self.formData.place = self.$store.state.meetings.current.place
                self.formData.address = self.$store.state.meetings.current.address
                self.date = new Date(self.$store.state.meetings.current.dt_start)
                self.time_start = new Date(self.$store.state.meetings.current.dt_start)
            })
        }
    }
}
</script>

<style scoped>
h2 {
    text-align: left;
}

input::placeholder {
    color: rgba(67, 66, 36, 0.25);
}

.p-inputtext:enabled:focus,
.p-inputtext:enabled:hover {
    border-color: #2D2FB6;
    box-shadow: 0 0 2px 2px rgba(45, 47, 182, 0.2);
}


/*.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {*/
/*    border-color: #2D2FB6;*/
/*    color: #2D2FB6;*/
/*}*/

.save-button {
    border-radius: 6px;
    background: #2D2FB6;
    color: white;
    margin-bottom: 32px;
}

.save-button.p-disabled, .save-button.p-component:disabled {
    opacity: 0.25
}

.delete-button {
    border-radius: 6px;
    background: #ff0000;
    color: white;
    border: #ff0000;
    margin-bottom: 32px;
}

.save-button.p-disabled, .save-button.p-component:disabled {
    opacity: 0.25
}
</style>