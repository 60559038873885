import VueRouter from 'vue-router';
import Vue from 'vue'
import MeetingForm from "@/views/MeetingForm";
import HomePage from "@/views/HomePage";
import ClubPage from "@/views/ClubPage";

const routes = [
    {
        path: '/',
        component: HomePage
    },
    {
        path: '/clubs/:id',
        component: ClubPage
    },
    {
        path: '/clubs/:id/meetings/new',
        component: MeetingForm,
        name: 'new-meeting'
    },
    {
        path: '/clubs/:id/meetings/:meeting_id',
        component: MeetingForm,
        name: 'change-meeting'
    },
]
Vue.use(VueRouter)

export const router = new VueRouter({
    mode: 'history',
    routes
})
