<template>
    <div class="p-d-flex p-flex-column">
        <router-link
            class="card create"
            :to="'/clubs/' + $route.params.id + '/meetings/new'"
        >
            <h3>Добавить встречу</h3>
        </router-link>
        <h2>Встречи клуба</h2>
        <router-link
            v-for="meeting in $store.state.meetings.list"
            :key="meeting['id']"
            v-show="!closedMeeting(meeting)"
            class="card meeting"
            :to="'/clubs/' + $route.params.id + '/meetings/' + meeting['id']"
        >
            <span>{{ formatDate(meeting['dt_start']) }}</span>
            <h3>{{ meeting['name'] }}</h3>
        </router-link>
        <div
            v-for="meeting in $store.state.meetings.list"
            :key="meeting['id']"
            v-show="closedMeeting(meeting)"
            class="card meeting closed"
        >
            <span>{{ formatDate(meeting['dt_start']) }}</span>
            <h3>{{ meeting['name'] }}</h3>
        </div>
        <div class="card meeting" v-if="$store.state.meetings.list.length === 0">

            <h3>Встреч пока нет</h3>
            <span class="empty">Создайте первую, нажав на кнопку выше</span>
        </div>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ClubsPage",
    mounted: function () {
        this.$store.dispatch('meetings/syncAPIList', this.$route.params.id)
    },
    methods: {
        formatDate: function (date) {
            return moment(date).format('lll')
        },
        closedMeeting: function (meeting) {
            return moment(meeting['dt_start']).isBefore(moment());

        }
    }
}
</script>

<style scoped>
.closed {
    background: rgba(0,0,0,0.1)
}

.create {
    background: #2D2FB6;
    color: white;
    margin-bottom: 32px;
}

h2 {
    text-align: left;
    padding-left: 16px;
}


.meeting {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.meeting span {
    margin-bottom: 8px;
    color: #2D2FB6;
}

.meeting span.empty {
    margin-top: 8px;
}

.meeting * {
    text-align: left;
}
</style>