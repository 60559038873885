import axios from "axios";
import {store} from "@/store";


const state = () => ({
    baseDomain: 'https://api.itmostudents.ru',
    access_token: null,
    api_client: null,
})


const actions = {
    async createAxiosClient({ state, commit }) {
        const client = axios.create({
            baseURL: store.state.config.baseDomain
        })
        client.interceptors.request.use(async function (config) {
            const access_token = state.access_token
            config['headers']['Authorization'] = 'Bearer ' + access_token
            return config
        }, function (error) {
            return Promise.reject(error);
        });
        // client.interceptors.response.use(async function (response) {
        //     return response
        // }, async function (error) {
        //     if (error.response && error.response.status && error.response.status === 401) {
        //         await dispatch('logInRedirect')
        //         return Promise.reject('401 returned')
        //     } else if (!error.response) {
        //         // const toast = await toastController
        //         //     .create({
        //         //         header: rootGetters['translations/getTranslation']['smth_wrong'],
        //         //         message: rootGetters['translations/getTranslation']['check_internet'],
        //         //         duration: 3000,
        //         //         color: 'danger',
        //         //     })
        //         // await toast.present()
        //         return Promise.reject('error')
        //     } else if (error.response.status >= 500) {
        //         // const toast = await toastController
        //         //     .create({
        //         //         header: rootGetters['translations/getTranslation']['our_problem'] ,
        //         //         message: rootGetters['translations/getTranslation']['server_error'],
        //         //         duration: 3000,
        //         //         color: 'danger',
        //         //     })
        //         // await toast.present()
        //         return Promise.reject('error 500')
        //     } else if (error.response.status === 403 && error.response.data['code'] === 4033) {
        //         commit('setNeedsUpdate')
        //     } else {
        //         return error.response
        //     }
        // })
        commit('setConfigVar', ['api_client', client])
    }
}

const mutations = {
    setConfigVar(state, [name, value]) {
        state[name] = value
    },
    updateAccessToken(state, data) {
        state['access_token'] = data
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}